.header-container {
    display: flex;
    justify-content: center;
    height: 149px;
    color: var(--white);
}

.header-parent {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 70%;
    color: var(--white);
}

.header-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 250px;
}

.header-logo-background {
    height: 35%;
    width: 35%;
    border-radius: 50%;
    background-image: url("../../../assets/Home/anhengineer.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}

.header-options {
    display: flex;
    align-items: center;
}

.header-option {
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
}
.header-hamburger {
    display: none;
    color: var(--white);
}
.header-option-separator {
    margin: 0 50px 0 0;
    color: var(--white);
}
.selected-header-option {
    color: var(--dark-orange);
}

/*IPAD PRO RESPONSIVENESS*/
@media only screen and (max-width: 1110px) {
    .header-parent {
        width: 80%;
    }
}
@media only screen and (max-width: 970px) {
    .header-hamburger {
        display: block;
    }
    .header-parent {
        width: 100%;
        padding: 0 40px;
        position: relative;
    }
    .header-options {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: -100%;
        transition: all 0.8s ease-out;
        top: 0;
        height: 100vh;
        width: 100%;
        justify-content: space-around;
        background-color: #1f2235;
        z-index: 1000;
        opacity: 0.5;
    }
    .header-option-separator {
        margin: 0;
    }
    .selected-header-option {
        color: var(--white);
    }
    .header-hamburger-bars {
        font-size: 36px;
        cursor: pointer;
        margin: 0 10px;
        color: var(--white);
    }
    .show-hamburger-options {
        left: 0;
        opacity: 1;
    }
}
